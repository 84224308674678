<template>
  <div>
    <Loading v-if="isloading" dialog="isloading" title="جاري انشاء باقة جديد"

    />
      <v-dialog
          width="60%"
          v-model="isDialog">

        <v-card elevation="0">
          <v-card-title>
            <span class="text-h5">   {{isUpdate?`  ${content.name} `:'انشاء باقة '}}</span>
          </v-card-title>
          <v-card-text>
            <v-container >
              <v-row >
                <v-col>
                  <v-card ref="form" elevation="0">
                    <v-card-text>
                      <v-form v-model="isFormValid" no-gutters>

                        <v-row    >
                          <v-col cols="12" md="6" sm="8" xs="12" no-gutters>
                            <v-text-field

                                ref="inputRef"
                                outlined
                                dense
                                :rules="[() => !!full_name || 'يجب ادخال اسم الباقة']"
                                filled
                                color="rgb(200, 200, 200)"
                                type="name"
                                label="اسم الباقة"
                                v-model="full_name"
                                value=""
                                class="input-group--focused"
                            ></v-text-field>


                          </v-col>

                          <v-col cols="12" md="6" sm="12" xs="12" no-gutters>
                            <v-text-field

                                ref="inputRef"
                                outlined
                                dense
                                :rules="[() => !!number || 'يجب ادخال عدد ايام الانتهاء']"
                                filled
                                color="rgb(200, 200, 200)"
                                type="number"
                                label="تاريخ الانتها عدد الايام"
                                v-model="number"
                                value=""
                                class="input-group--focused"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="pa-0" >
                          <v-col cols="12" md="6" sm="8" xs="12" no-gutters>
                            <v-text-field
                                ref="inputRef"
                                outlined
                                dense
                                :rules="[() => !!price || 'يجب ادخال سعر الباقة']"
                                filled
                                color="rgb(200, 200, 200)"
                                type="number"
                                label="سعر الباقة"
                                v-model="price"
                                value=""
                                class="input-group--focused"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" sm="12" xs="12">
                            <v-text-field

                                ref="inputRef"
                                outlined
                                dense

                                filled
                                color="rgb(200, 200, 200)"

                                label="وصف الباقة (اختياري)"
                                v-model="description"

                                value=""
                                class="input-group--focused"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-text-field
                            v-if="!is_superuser"
                            ref="inputRef"
                            outlined
                            dense
                            :rules="[() => !!quota || 'يجب ادخال عدد رسائل الباقة']"
                            filled
                            color="rgb(200, 200, 200)"
                            type="number"
                            label="عدد رسائل الباقة"
                            v-model="quota"
                            value=""
                            class="input-group--focused"
                        ></v-text-field>
                        <v-row  >
                          <v-col cols="12" md="4" sm="4" xs="6" no-gutters>
                            <v-checkbox v-model="is_superuser" label="عدد رسائل غير محدود"/>
                          </v-col>
                          <v-col cols="12" md="4" sm="4" xs="6" no-gutters>
                            <v-switch
                                v-model="switchItems"
                                label="تفعيل الباقة"
                                color="success"
                                value="is_active"
                                hide-details
                            ></v-switch>
                          </v-col>

                          <v-col cols="12" md="3" sm="4" xs="6" no-gutters>
                            <v-switch
                                v-model="switchItems"
                                label="الرد الالي"
                                color="success"
                                value="is_relay"
                                hide-details
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" md="3" sm="4" xs="6" no-gutters>
                            <v-switch
                                v-model="switchItems"
                                label="ارسال المجموعات"
                                color="success"
                                value="is_send_group"
                                hide-details
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" md="3" sm="4" xs="6" no-gutters>
                            <v-switch
                                v-model="switchItems"
                                label="ارسال ملفات"
                                color="success"
                                value="is_send_pdf"
                                hide-details
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" md="3" sm="4" xs="6" no-gutters>
                            <v-switch
                                v-model="switchItems"
                                label="ارسال صور"
                                color="success"
                                value="is_send_image"
                                hide-details
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" md="3" sm="4" xs="6" no-gutters>
                            <v-switch
                                v-model="switchItems"
                                label="ارسال فيديوهات "
                                color="success"
                                value="is_send_video"
                                hide-details
                            ></v-switch>
                          </v-col>
                        </v-row>

                      </v-form>

                    </v-card-text>
                  </v-card>
                  <v-row>

                    <v-btn class="ma-2"
                           width="20%"
                           outlined
                           color="paink"

                           @click="close"
                    >
                      الغاء
                    </v-btn>

                    <v-btn class="ma-2"
                           width="20%"
                           outlined
                           color="green"
                           :disabled="!isFormValid"
                           @click="createAccount()"
                    >
                      {{isUpdate?'تحديث':'انشاء'}}
                    </v-btn>

                  </v-row>
                </v-col>
              </v-row>
            </v-container>

          </v-card-text>


        </v-card>
        <v-alert
            class="mt-5"
            left
            border="left"
            color="red"
            dismissible
            elevation="9"
            type="error"
            v-model="isShow"
        >{{msgError}}</v-alert>
        <SuccessDialog :title="msgSucess"  v-if="successDialog" :success-dialog.sync="successDialog" />
      </v-dialog>




  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessSended.vue';
import store from "@/store";
import Base64 from "@/services/Base64";

export default {
  components: {
    Loading,
    SuccessDialog

  },

  props:["content","isUpdate","deviceToken","isDialog"],

  data () {
    return {

      isFormValid: false,
      isloading: false,
      full_name:null,
      price:null,
      description:null,
      switchItems:[],
      number:null,
      is_active:false,
      quota:null,
      is_superuser:false,
      isShow: false,
      successDialog: false,
      msgSucess:this.isUpdate?"تم تحديث الباقة بنجاح":"تم انشاء الباقة بنجاح",
      msgError:"فشل ",

    }
  },

  methods: {
    close() {
      this.$emit('update:isDialog', false);
    },
    async createAccount() {



      try {

        this.isloading =true;

        const body = {
          'name': this.full_name,
          "description": this.description??".",
          "active": this.switchItems.find(value => value==="is_active")=='is_active',
          "quota": this.quota??"0",
          "quota_type": this.is_superuser?"unlimited":"limited",
          "expired_date": this.number,
          "price": this.price,
          'is_relay':     this.switchItems.find(value => value==="is_relay")=='is_relay',
          'is_send_group': this.switchItems.find(value => value==="is_send_group")=='is_send_group',
          'is_send_video': this.switchItems.find(value => value==="is_send_video")=='is_send_video',
          'is_send_image': this.switchItems.find(value => value==="is_send_image")=='is_send_image',
          'is_send_pdf':    this.switchItems.find(value => value==="is_send_pdf")=='is_send_pdf',

        };

        let response;
        if(!this.isUpdate)
          response= await PushServicesSocket.GetPostData("/product/create",body);
        else
           response = await PushServicesSocket.PutUpdateData("/product/update/"+this.content?.serial,body);


        if(response.status)
        {
          this.msgSucess=this.isUpdate?response.message:  (this.msgSucess+"  "+this.full_name);
          this.successDialog = true;
          this.isloading = false;


          setTimeout(() => {
                this.close();
            this.$root.$emit('updatePackage');
              },3000);
        }else{
          this.msgError = response.error;
          this.isShow = true;
          this.isloading =false;

        }


      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }


    },
    initializeUpdate(){
      this.full_name = this.content.name;
      this.description = this.content.description;
      this.quota = this.content.quota;
      this.number = this.content.expired_date;
      this.price = this.content.price;
      this.is_superuser = this.content.quota_type=="unlimited";

      if(this.content.active===true)
        this.switchItems.push('is_active');
      if(this.content.is_relay===true)
        this.switchItems.push('is_relay');
      if(this.content.is_send_group===true)
        this.switchItems.push('is_send_group');
      if(this.content.is_send_video===true)
        this.switchItems.push('is_send_video');
      if(this.content.is_send_image===true)
        this.switchItems.push('is_send_image');
      if(this.content.is_send_pdf===true)
        this.switchItems.push('is_send_pdf');


    }

  },
  async created() {
    if(this.isUpdate)
      this.initializeUpdate();

  }
}
</script>

<style scoped>


</style>