<template>
  <div>
    <Loading v-if="isloading" dialog="isloading" title="انشاء تفعيل الباقة "

    />
      <v-dialog
          width="60%"
          v-model="isDialog">

        <v-card elevation="0">
          <v-card-title>
            <span class="text-h5">   {{isUpdate?`  ${content.name} `:'تفعيل الباقة '}}</span>
          </v-card-title>
          <v-card-text>
            <v-container >
              <v-row >
                <v-col>
                  <v-card ref="form" elevation="0">
                    <v-card-text>
                      <v-form v-model="isFormValid" no-gutters>
                        <v-autocomplete
                            auto-select-first
                            chips
                            clearable
                            ref="inputRef"
                            outlined
                            dense
                            filled
                            color="rgb(200, 200, 200)"
                            v-model="selectedUser"
                            :items="users"
                            no-data-text="لا يوجد مستخدمين"

                            label="اختار المستخدم"

                            @input="setSelectedUser"
                            item-value="id"
                            :rules="[() => !!selectedUser || 'يجب اختيار المستخدم']"
                            :item-text="item => item.name"
                            deletable-chips
                        ></v-autocomplete>
                        <loading v-if="isloading"/>
                        <v-select
                            v-if="selectedUser&&!isloading"
                            ref="inputRef"
                            dense
                            filled
                            no-data-text="لا يوجد اجهزة"
                            outlined
                            label="الجهاز"
                            solo
                            v-model="selectedDevice"
                            @input="setSelectedDevice"
                            hide-selected
                            :rules="[() => !!selectedDevice || 'يجب اختيار الجهاز']"
                            :items="devicesItems"
                            clearable
                            item-value="serial"
                            :item-text="item => item.number+' ::: '+item.name"
                        ></v-select>
                        <v-select
                            v-if="selectedUser&&!isloading&&selectedDevice"
                            ref="inputRef"
                            dense
                            filled
                            no-data-text=""
                            outlined
                            label="الباقات"
                            solo
                            v-model="selectedPackage"
                            @input="setSelectedPackage"
                            hide-selected
                            :rules="[() => !!selectedPackage || 'يجب اختيار الباقة']"
                            :items="packages"
                            clearable
                            item-value="serial"
                            :item-text="item => item.name"
                        ></v-select>
                      </v-form>

                    </v-card-text>
                  </v-card>
                  <v-row>

                    <v-btn class="ma-2"
                           width="20%"
                           outlined
                           color="paink"

                           @click="close"
                    >
                      الغاء
                    </v-btn>

                    <v-btn class="ma-2"
                           width="20%"
                           outlined
                           color="green"
                           :disabled="!isFormValid"
                           @click="createAccount()"
                    >
                      {{isUpdate?'تحديث':'تفعيل'}}
                    </v-btn>

                  </v-row>
                </v-col>
              </v-row>
            </v-container>

          </v-card-text>


        </v-card>
        <v-alert
            class="mt-5"
            left
            border="left"
            color="red"
            dismissible
            elevation="9"
            type="error"
            v-model="isShow"
        >{{msgError}}</v-alert>
        <SuccessDialog :title="msgSucess"  v-if="successDialog" :success-dialog.sync="successDialog" />
      </v-dialog>




  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessSended.vue';

import store from "@/store";
import Base64 from "@/services/Base64";

export default {
  components: {
    Loading,
    SuccessDialog

  },

  props:["content","isUpdate","isDialog","users","packages"],

  data () {
    return {

      isFormValid: false,
      isloading: false,
      isShow: false,
      successDialog: false,
      msgSucess:this.isUpdate?"تم تحديث الباقة بنجاح":"تم تفعيل الباقة بنجاح",
      msgError:"فشل ",
      devicesItems:[],
      selectedDevice:null,
      selectedPackage:null,
      selectedUser:"",


    }
  },

  methods: {
    setSelectedDevice(value){
      this.selectedDevice = value;

    },
    setSelectedPackage(value){
      this.selectedPackage = value;

    },
    async setSelectedUser(value){
      this.selectedUser = value;

      await this.getAllDevices();
    },
    close() {
      this.$emit('update:isDialog', false);
    },
    async getAllDevices(){
      this.isloading = true;
      this.devicesItems = await PushServicesSocket.GetData(`/device/getbyuser/${this.selectedUser}`);
      if(this.devicesItems.status)
      {

        this.devicesItems = this.devicesItems.data;

      }


      this.isloading = false;
    },

    async createAccount() {



      try {

        this.isloading =true;

        const body = {
          "device_serial":this.selectedDevice,
          "product_serial":this.selectedPackage

        };

        let response;
        if(!this.isUpdate)
          response= await PushServicesSocket.GetPostData("/movement/create",body);
        else
           response = await PushServicesSocket.PutUpdateData("/movement/update/"+this.content?.serial,body);


        if(response.status)
        {
          this.msgSucess=this.isUpdate?response.message:  (this.msgSucess);
          this.successDialog = true;
          this.isloading = false;

          setTimeout(() => {
                this.close();
            this.$root.$emit('updateMovePackage');
              },3000);
        }else{
          this.msgError = response.error;
          this.isShow = true;
          this.isloading =false;

        }


      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }


    },
    initializeUpdate(){
      this.full_name = this.content.name;
      this.description = this.content.description;
      this.quota = this.content.quota;
      this.number = this.content.expired_date;
      this.price = this.content.price;
      this.is_active = this.content.active;
      this.is_superuser = this.content.quota_type=="unlimited";


    }

  },
  async created() {
    if(this.isUpdate)
      this.initializeUpdate();

  }
}
</script>

<style scoped>


</style>